<template lang="pug">
    .rodolfistas
        v-snackbar(
            :timeout="3000"
            :top="true"
            :right="true"
            color="error"
            v-model="error")
                | {{ errorText }}
                v-btn(flat dark @click.native="error = false") {{ $t('cerrar') }}
        v-layout(max-width row wrap)
            v-flex(xs12)
                v-layout(max-width row wrap)
                    v-flex.mt-5(xs12 md6)
                        div.text-xs-center.mx-4.intro-text
                            h2.ma-0 {{ $t('ÚNETE A LA RED') }}
                            h1.ma-0(style="color:#2A3D59") RODOLFISTAS.COM
                            h2.ma-0 {{ $t('¡LA COALICIÓN ES CON EL PUEBLO!') }}
                        img.silueta-responsive.full-width(src="/public/img/landings/silueta.png")
                        v-form.form.pa-4.my-3.mx-auto
                            h2.font-300.text-xs-center
                                | {{ $t('Inicia sesión aquí si ya tienes una cuenta en la red') }} 
                                b {{ this.currentNetwork.name }}
                            v-layout(row wrap)
                                v-flex(xs12)
                                    v-text-field.medium(v-model="form.email" :label="$t('Correo electrónico o teléfono')" type="text")
                                v-flex(xs12)
                                    v-text-field.medium(
                                        v-model="form.password" 
                                        :label="$t('Contraseña')"
                                        :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                        :append-icon-cb="() => (e1 = !e1)"
                                        :type="e1 ? 'password' : 'text'")
                            v-divider.mt-4
                            .btns.btns-right
                                v-btn.white--text(color="blue rodolfista" @click.native="login" :disabled="loadDisabled") {{ loadState }}
                                v-btn.white--text(color="blue rodolfista" small @click.native.stop="recoverModal = true") {{ $t('Recuperar contraseña') }}
                        .register-text.mx-auto.text-xs-center.mt-2
                            v-btn.white--text(color="blue rodolfista" :to="registerTo") {{ $t('Regístrate') }}
                            h2.mt-2.font-300.text-xs-center.title-register
                                | {{ $t('Si aún no tienes una cuenta en la red') }} 
                                b {{ this.currentNetwork.name }}
                        v-dialog(v-model="recoverModal" max-width="600")
                            v-form
                                v-card
                                    v-card-title {{ $t('Recuperar contraseña') }}
                                    v-card-text
                                        v-layout(row wrap)
                                            v-flex(xs12)
                                                v-text-field.medium(v-model="email" :label="$t('Correo electrónico')" type="email")
                                    v-card-actions
                                        .btns.btns-right
                                            v-btn(color="secondary" small @click.native="recoverModal = false") {{ $t('Cancelar') }}
                                            v-btn(color="primary" large @click.native="restoreKey" :disabled="resDisabled") {{ resState }}
                    v-flex.mt-2.silueta-normal(xs12 md6)
                        img.full-width(src="/public/img/landings/silueta.png")
            v-flex.footer.py-5.px-5.mt-4(xs12)
                v-layout(max-width row wrap)
                    v-flex.d-flex.align-center(xs12 sm6)
                        img.wp-icon.mr-2(src="/public/img/landings/whatsapp-256.png")
                        b.ml-2 {{ $t('Si tienes un inconveniente con tu registro o tienes alguna duda, pregunta o sugerencia, ESCRIBENOS y te solucionamos el problema de forma inmediata.') }}
                    v-flex.mt-3(xs12 sm6)
                        h3 {{ $t('Líneas rodolfistas') }}
                        v-layout(max-width row wrap)
                            v-flex(xs4 xl6)
                                span.d-block 
                                    b ANDRES:&nbsp;
                                    | 324-4762-238
                                span.d-block 
                                    b LAURA:&nbsp; 
                                    | 324-5671-228
                                span.d-block 
                                    b OSCAR:&nbsp;
                                    | 324-5035-667
                            v-flex(xs4 xl6)
                                span.d-block 
                                    b STEFANIA:&nbsp;
                                    | 324-5602-763
                                span.d-block 
                                    b ZURIT:&nbsp;
                                    | 324-6521-982
                                span.d-block 
                                    b PAULA:&nbsp;
                                    | 324-4214-763
                            v-flex(xs4 xl6)
                                span.d-block 
                                    b STEFANY:&nbsp;
                                    | 324-5602-845
                                span.d-block 
                                    b DANIELA:&nbsp;
                                    | 324-4918-573
                                span.d-block 
                                    b LAURA V:&nbsp;
                                    | 324-3176-879
                            v-flex(xs4 xl6)
                                span.d-block 
                                    b SOFIA:&nbsp;
                                    | 301-5765-502
</template>

<script>

    import fn from 'mixins/fn'
    export default {
        props: [
            'currentNetwork'
        ],
        mixins: [fn],
        data(){
            return {
                e1: true,
                recoverModal: false,
                form: {
                    email: '',
                    password: '',
                    networkid: ''
                },
                error: false,
                errorText: '',
                email: '',
                networkid: '',
                success: false,
                successText: '',
                loadOptions: {
                    normal: 'Ingresar',
                    load: 'Ingresando...'
                },
                loadDisabled: false,
                loadState: '',
                resOptions: {
                    normal: 'Enviar',
                    load: 'Enviando...'
                },
                resDisabled: false,
                resState: ''
            }
        },
        computed: {

            registerTo(){

                if(this.$route.query.invite){
                    return '/register?invite=' + this.$route.query.invite
                }else{
                    return '/register'
                }
            }
        },
        created(){
            this.loadState = this.loadOptions.normal
            this.resState = this.resOptions.normal
        },
        methods: {

            login(){
                
                this.loadState = this.loadOptions.load
                this.loadDisabled = true
                
                this.$api(this, (xhr) => {
                    xhr.post('/login', this.$qs.stringify(this.form)).then((r) => {

                        let data = r.data
                        this.loadState = this.loadOptions.normal
                        this.loadDisabled = false
                        
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            
                            let success = data.data.hasOwnProperty('success') ? data.data.success : null
                            if(success != null){
                                return this.$router.push(this.registerTo)
                            }

                            this.$setItem('xtoken', data.data.token, () => {
                                return this.$router.push({path : this.isadmin ? '/dasboard' : '/home'})
                            })
                        }
                    }).catch(() => {})
                })
			},

            restoreKey(){

                this.resState = this.resOptions.load
                this.resDisabled = true

                this.$api(this, (xhr) => {
                    xhr.post('/restore-key', this.$qs.stringify({
                        email     : this.email,
                        networkid : this.networkid
                    })).then((r) => {

                        this.resState = this.resOptions.normal
                        this.resDisabled = false
                        
                        let data = r.data
                        if(!data.response){
                            this.error = true
                            this.errorText = data.message
                        }else{
                            this.recoverModal = false   
                            this.success = true
                            this.successText = data.message
                            this.email = ''
                        }
                    }).catch(() => {
                        this.resState = this.resOptions.normal
                        this.resDisabled = false
                    })
                })
            }
        }
    }

</script>